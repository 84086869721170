/* eslint-disable react-hooks/exhaustive-deps */
import InputSelect from "@/components/Input/InputSelect";
import {Form, Select, Tag, Popover, Button, message} from "antd";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {ManOutlined, PlusOutlined, WomanOutlined} from "@ant-design/icons";
import { css } from "@emotion/css";
import {BASE_URL} from "@/util/config";

export const GetUserInfo = (fld) => {
  // const userLocal = localStorage.getItem('userData') || "";
  const userLocal = secureLocalStorage.getItem("userData") || "";

  if (userLocal !== "") {
    // const uj = JSON.parse(userLocal);
    return userLocal[fld];
  }
  return "";
};

export const GetActivityStatusTag = ({ status }) => {
  switch (status) {
    case 4:
      return <Tag color="#34495e">Completed</Tag>;
    case 1:
      return <Tag color="#34495e">Pending</Tag>;
    case 2:
      return <Tag color="#34495e">In Progress</Tag>;
    case 3:
      return <Tag color="#34495e">Canceled</Tag>;
    default:
      return <Tag color="#34495e">Pending</Tag>;
  }
};

export const GetUserImage = () => {
  const userLocal = secureLocalStorage.getItem("userData");
  // const userJson = JSON.parse(userLocal);
  console.log(userLocal);
  if (userLocal?.image !== null) {
    return BASE_URL+userLocal?.image;
  }
  return "/assets/images/placeholder.jpg";
};

export const GetStatusTag = ({ status }) => {
  switch (status) {
    case 1:
      return <Tag color="#34495e">Active</Tag>;
    case 0:
      return <Tag color="#87646b">Inactive</Tag>;
    default:
      return <Tag color="#aab5bd">Pending</Tag>;
  }
};

export const GetMemberStatusTag = ({ status }) => {
  switch (status) {
    case true:
      return <Tag color="#34495e">Yes</Tag>;
    case false:
      return <Tag color="#87646b">No</Tag>;
    default:
      return <Tag color="#aab5bd">No</Tag>;
  }
}

export function queryStringToJson(queryString) {
  const trimmedString = queryString.replace("?", "");
  const pairs = trimmedString.split("&");
  const jsonObject = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    // jsonObject[key] = decodeURIComponent(value);
    jsonObject[key] = !isNaN(value) ? Number(value) : decodeURIComponent(value);
  });

  return jsonObject;
}

export const GetMemberType = ({ type }) => {
  switch (type) {
    case 1:
      return "Member";
    case 2:
      return "Officer";
    case 3:
      return "Minister";
    default:
      return "Member";
  }
};

export const SelectStatus = ({
  name = "status",
  label = "Status",
  required = false,
  initialValue = null,
  index,
    items = []
}) => {
  return (
    <Form.Item
      name={index + 1 ? [index, name] : name}
      label={label}
      initialValue={initialValue}
      rules={[{ required: required }]}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
    `}
    >
      <Select clearIcon={true} placeholder="Select an status" style={{ width: "100%" }}>
        {
          items.map((item) => (
              <Select.Option value={item.value}>{item.name}</Select.Option>
          ))
        }
      </Select>
    </Form.Item>
  );
};

export const SelectMemberStatus = ({
  name = "status",
  label = "Membership Status",
  required = false,
  index,
}) => {
  return (
    <Form.Item
      name={index + 1 ? [index, name] : name}
      label={label}
      initialValue={1}
      rules={[{ required: required }]}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
      `}
    >
      <Select placeholder="Select an status" style={{ width: "100%" }}>
        <Select.Option value={1}>Active</Select.Option>
        {/*<Select.Option value={0}>Inactive</Select.Option>*/}
        <Select.Option value={2}>Backslider</Select.Option>
        <Select.Option value={3}>Interdicted</Select.Option>
        <Select.Option value={4}>Suspended</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const SelectPriority = ({
                                     name = "priority",
                                     label = "Display Priority",
                                     required = false,
                                     index,
                                  initialValue = null,
                                   }) => {
  return (
      <Form.Item
          name={index + 1 ? [index, name] : name}
          label={label}
          initialValue={initialValue}
          rules={[{ required: required }]}
          className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
      `}
      >
        <Select clearIcon={true} placeholder="Select an option" style={{ width: "100%" }}>
          {Array.from({ length: 101 }, (_, x) => (
              <Select.Option key={x} value={x}>{x}</Select.Option>
          ))}
        </Select>
      </Form.Item>
  );
};

export const SelectMaritalStatus = ({
  name = "marital_status",
  label = "Marital Status",
  required = false,
  index,
}) => {
  return (
    <Form.Item
      name={index + 1 ? [index, name] : name}
      label={label}
      initialValue={null}
      rules={[{ required: required }]}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
      `}
    >
      <Select placeholder="Select an option" style={{ width: "100%" }}>
        <Select.Option value="Single">Single</Select.Option>
        <Select.Option value="Married">Married</Select.Option>
        <Select.Option value="Divorced">Divorced</Select.Option>
        <Select.Option value="Separated">Separated</Select.Option>
        <Select.Option value="Widowed">Widowed</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const SelectMarriageType = ({
  name = "type_of_marriage",
  label = "Type of Marriage",
  required = false,
  index,
}) => {
  return (
    <Form.Item
      name={index + 1 ? [index, name] : name}
      label={label}
      initialValue={null}
      rules={[{ required: false }]}
    >
      <Select placeholder="Select an option" style={{ width: "100%" }}>
        <Select.Option value="Customary">Customary</Select.Option>
        <Select.Option value="Ordinance">Ordinance</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const ParseDateFormat = (value) => {
  if (value === null || value === undefined) {
    return "";
  } else {
    const from = moment(value[0]).format("YYYY-MM-DD");
    const to = moment(value[1]).format("YYYY-MM-DD");
    return [from, to];
  }
};

export const getQueryStrings = (obj) => {
  const str = [];

  Object.keys(obj).forEach((p) => {
    if (
      Object.prototype.hasOwnProperty.call(obj, p) &&
      obj[p] !== undefined &&
      obj[p] !== null &&
      obj[p] !== ""
    ) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  });

  return str.join("&");
};

export const AddActionButton = (url) => (
  <>
    <div className="icon row no-print">
      <div className="col-md-6"></div>
      <div className=" col-md-6 right">
        <Link
          to={url}
          className="btn btn-text-adjust btn-circle-md"
          title="Font Size Increase"
        >
          <PlusOutlined />
        </Link>
      </div>
    </div>
  </>
);

export const CisUI = () => {
  const HeaderRequest = () => {
    const token = secureLocalStorage.getItem("token");
    return { headers: { Authorization: token } };
  };

  const getNumber = (amount) => {
    return new Intl.NumberFormat("en-us").format(amount);
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(value);

  const getCurrencyFormated = (amount) => {
    return (
      <div style={{ textAlign: "right", marginRight: "30px" }}>
        {numberFormat(amount || 0)}
      </div>
    );
  };

  const getCurrencyFormated1 = (amount) => {
    if (amount >= 0) {
      return numberFormat(amount || 0);
    } else {
      const amt = numberFormat(amount || 0);
      return "(" + amt.replace("-", "") + ")";
    }
  };

  const getUserInfo = (fld) => {
    const userLocal = secureLocalStorage.getItem("userData") || "";
    // let  userID = 0;
    // let userName = "";
    if (userLocal !== "") {
      // const uj = JSON.parse(userLocal);
      return userLocal[fld];
    }
    return "";
  };

  const loader = (
    <div className="loader-table">
      <img src="/assets/images/loader.svg" alt="" />
    </div>
  );

  const showLoading = (
    <div id="loader" className="loading_view" style={{ display: "none" }}>
      <div className="loading_center2">
        <div className="loader-table" style={{ textAlign: "center" }}>
          <img
            style={{ background: "transparent", width: "50px", height: '50px' }}
            src="/assets/images/cop_loading.gif"
            alt=""
          />
        </div>
      </div>
    </div>
  );

  const getMonth = (month) => {
    if (month !== null) {
      moment.locale("en-gb");
      const date =
        month.toString().substr(0, 4) +
        "-" +
        month.toString().substr(4, 2) +
        "-01";
      return moment(date).format("MMMM YYYY");
    }
  };

  const ParseDateFormat = (value) => {
    if (value === null || value === undefined) {
      return "";
    } else {
      const from = moment(value[0]).format("YYYY-MM-DD");
      const to = moment(value[1]).format("YYYY-MM-DD");
      return [from, to];
    }
  };

  const ParseDateFormatFromMonth = (value) => {
    if (value === null || value === undefined) {
      return "";
    } else {
      return moment(value).format("YYYY-MM-01");
    }
  };

  const getWeekRange = (date) => {
    moment.locale("en");
    return moment(date).clone().startOf("week").format("YYYY-MM-DD");
  };

  return {
    loader: loader,
    getMonth: getMonth,
    getNumber: getNumber,
    getCurrencyFormated1: getCurrencyFormated1,
    getCurrencyFormated: getCurrencyFormated,
    getWeekRange: getWeekRange,
    ParseDateFormatFromMonth: ParseDateFormatFromMonth,
    ParseDateFormat: ParseDateFormat,
    getUserInfo: getUserInfo,
    HeaderRequest: HeaderRequest(),
    showLoading: showLoading,
    AddActionButton: AddActionButton,
    addAction: AddActionButton,
  };
};

export const perPage = 50;

export const dateFormat = "MM/DD/YYYY";
export const dateTimeFormat = "MMM Do YYYY, h:mm:ss A";
export const dateFormatText = "MMMM DD, YYYY";

export const AuditGetStatusTag = ({ status }) => {
  switch (status) {
    case 1:
      return <Tag color="#34495e">Logged In</Tag>;
    case 0:
      return <Tag color="#87646b">Logged Out</Tag>;
    default:
      return <Tag color="#aab5bd">None</Tag>;
  }
};
export const ParseDateValue = (field) => {
  switch (field) {
    case "issue_date":
      return true;
    case "date":
      return true;
    case "dob":
      return true;
    case "approved_at":
      return true;
    default:
      return false;
  }
};

export const ParseIntValue = (field) => {
  switch (field) {
    case "status":
      return true;
    case "company_type":
      return true;
    case "com_id":
      return true;
    case "company_id":
      return true;
    case "user_id":
      return true;
    case "member_id":
      return true;
    case "member_type":
      return true;
    case "region_id":
      return true;
    case "district_id":
      return true;
    case "local_id":
      return true;
    case "usefor":
      return true;
    case "country":
      return true;
    case "type_id":
      return true;
    case "action_id":
      return true;
    case "p_group":
      return true;
    default:
      return false;
  }
};

export const SearchObject = (search) => {
  // console.log(JSON.stringify(search))
  let searchArray = search.replace("?", "").split("&");
  let newObj = {};
  searchArray.map((item, index) => {
    // console.log(item)
    let arr = item.split("=");
    if (ParseDateValue(arr[0])) {
      newObj[arr[0]] = arr[1] !== "" && moment(arr[1]);
    } else if (arr[0] === "dates") {
      let dates_array = decodeURIComponent(arr[1]).split(",");
      newObj[arr[0]] = arr[1] !== "" && [
        moment(dates_array[0]),
        moment(dates_array[1]),
      ];
    } else if (ParseIntValue(arr[0])) {
      newObj[arr[0]] = parseInt(arr[1]);
    } else if (arr[0] === "phone_no") {
      newObj[arr[0]] = "+" + decodeURIComponent(arr[1]);
    } else {
      newObj[arr[0]] = decodeURIComponent(arr[1]);
    }
  });

  return newObj;
};

export const EDUCATIONAL_LEVEL = [
  {
    id: 1,
    value: 1,
    name: "Below High School",
  },
  {
    id: 2,
    value: 2,
    name: "High School Certificate",
  },
  {
    id: 3,
    value: 3,
    name: "Associates",
  },
  {
    id: 4,
    value: 4,
    name: "Bachelors",
  },
  {
    id: 5,
    value: 5,
    name: "Masters",
  },
  {
    id: 6,
    value: 6,
    name: "Doctorate",
  },
  {
    id: 7,
    value: 7,
    name: "PHD",
  },
  {
    id: 8,
    value: 8,
    name: "Other",
  },
];

export const EducationalLevel = ({
  name = "education_level",
  label = "Educational Level",
}) => {
  return <InputSelect name={name} label={label} items={EDUCATIONAL_LEVEL} />;
};

export const convertDateInMoment = (value) => {
  // if (value === undefined || value === null || value === "") {
  //   return null;
  // } else {
  //   return moment(value);
  // }
  return value ? moment(value) : null;
};

export const GENDER = [
  {
    id: "Male",
    name: "Male",
  },
  {
    id: "Female",
    name: "Female",
  },
];

export const SelectGender = ({
  name = "gender",
  label = "Gender",
  required = false,
  ...arg
}) => {
  return (
    <InputSelect
      name={name}
      label={label}
      required={required}
      items={GENDER}
      placeholder="Select an option"
    />
  );
};

export const SOCIAL_MEDIA = [
  {
    _id: 1,
    id: 1,
    name: "Facebook",
    status: 1,
  },
  {
    _id: 2,
    id: 2,
    name: "Twitter",
    status: 1,
  },
  {
    _id: 3,
    id: 3,
    name: "Instagram",
    status: 1,
  },
  {
    _id: 4,
    id: 4,
    name: "LinkedIn",
    status: 1,
  },
  {
    _id: 5,
    id: 5,
    name: "YouTube",
    status: 1,
  },
  {
    _id: 6,
    id: 6,
    name: "WhatsApp",
    status: 1,
  },
  {
    _id: 7,
    id: 7,
    name: "Other",
    status: 1,
  },
];

export const PhoneAction = ({ number }) => {
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const WhatsApp = () => {
    const cleanedNumber = number.replace(/[^0-9+]/g, "");
    window.open("https://wa.me/" + cleanedNumber, "_blank");
    hide();
  };

  const SMS = () => {
    const cleanedNumber = number.replace(/[^0-9+]/g, "");
    window.open("/communication/sms?number=" + cleanedNumber, "_blank");
    hide();
  };

  return (
    <Popover
      content={
        <>
          <Button onClick={WhatsApp}>WhatsApp</Button>
          <Button onClick={SMS}>SMS</Button>
        </>
      }
      title="Would you like to send?"
      trigger="hover"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <span>{number}</span>
    </Popover>
  );
};

export const GetFormattedPhoneNum = ({ input }) => {
  var cleaned = ("" + input).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let phone = "";
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    phone = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    phone = input;
  }

  return <PhoneAction number={phone} />;
};

export const getUserDate = (value) => {
  if (value === null || value === undefined || value === "") {
    return "";
  } else {
    return moment(value).format("DD/MM/YYYY");
  }
};

export const DateRangeConvert = (value) => {
  if (value === null || value === undefined || value === "") {
    return "";
  } else {
    return moment(value[0]).format("DD/MM/YYYY")+" to "+moment(value[1]).format("DD/MM/YYYY");
  }
};

export const getSystemDate = (value) => {
  if (value === null || value === undefined || value === "") {
    return "";
  } else {
    return moment(value).format("YYYY-MM-DD");
  }
};

export const GetGenderNameWithIcon = ({ gen_id }) => {
  const gender = GENDER.find((item) => item.id === gen_id);
  if (gender && gender.name === "Male") {
    return (
      <>
        <ManOutlined style={{ color: "#0A2C47" }} /> {gender.name}
      </>
    );
  } else if (gender && gender.name === "Female") {
    return (
      <>
        <WomanOutlined style={{ color: "#c90076" }} /> {gender.name}
      </>
    );
  } else {
    return "";
  }
};

export const CopyToClipboard = (text) => {
  if (!navigator.clipboard) {
    // Clipboard API not available
    return
  }
  if (document.hasFocus()) {
    try {
      navigator.clipboard.writeText(text);
      //message.success("Clipboard added");
    } catch (e) {
      console.log(e.toString())
    }
  }
};

export const GetTransferStatus = ({ status }) => {
  switch (status) {
    case 1:
      return <Tag color="green">Approved</Tag>;
    case 2:
      return <Tag color="green">Approved From Source</Tag>;
    case 3:
      return <Tag color="red">Rejected</Tag>;
    default:
      return <Tag color="lime">Pending</Tag>;
  }
};

export const GetOPerationStatusTag = ({ status = 0 }) => {
  switch (status) {
    case 1:
      return <Tag color="#34495e">Approved</Tag>;
    case 0:
      return <Tag color="#87646b">Pending</Tag>;
    default:
      return <Tag color="#aab5bd">Pending</Tag>;
  }
};


// Capitalize first letter of each word
// Example: capSentenceFirstLetter('hello world') => 'Hello World'
export function capSentenceFirstLetter(sentence) {
  if (sentence === null || sentence === undefined) {
    return "";
  } else {
    return sentence
      .toLowerCase()
      .split(' ')
      ?.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
      .join(' ');
  }
}

// Capitalize first word first letter
// Example: capFirstWordFirstLetter('hello world') => 'Hello world'
export function capFirstWordFirstLetter(sentence) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });



export const ConnectionError = () => {
  // Network Error
  return "Ensure that your device has a stable and active internet connection. If the connection is lost during the request, a network error may occur.";
};

export const RequestError = () => {
  // Server Error
  return "Sorry, we encountered an issue while processing your request. Please check your input. If the problem persists, contact support for assistance.";
};

export const RequestErrorOverLoad = () => {
  // Server Error
  return "Sorry, we encountered an issue while processing your request due to high traffic. Please try again. If the problem persists, contact support for assistance.";
};

export const SessionExpired = () => {
  return "Your Login Session Has Expired, please login again";
};

export const getActivityStatusLable = (status) => {
  const ASTATUS = [
    "",
    "In Progress",
    "Achieved",
    "Not Achieved",
    "Not Applicable",
  ];
  // status = parseInt(status);
  // console.log(status)
  return ASTATUS[status];
};