import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form } from 'antd';
import { capSentenceFirstLetter } from '@/util/CISUI';
import { css } from '@emotion/css';

// NOTE: When you set data in CKEditor compoent. please check the data is not null or undefined otherwise it will throw error
export default function CustomCKEditor({
  name = 'content',
  label = 'Content',
  required = false,
  maxLength = 20000,
}) {
  // const intl = useIntl();
  return (
    <Form.Item
      label={capSentenceFirstLetter(label)}
      name={name}
      valuePropName="data"
      getValueFromEvent={(_event, editor) => {
        const data = editor.getData();
        return data;
      }}
      rules={[
        {
          required: required,
          message: 'This field is required!',
        },
        {
          // Max length of CKEditor
          max: maxLength,
          message: `Content must be less than ${maxLength} characters`,
        },
      ]}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
    `}
    >
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            'blockQuote',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
          height: `500px`
        }}
        styles={css`height: 600px !important;`}
      />
    </Form.Item>
  );
}

// Also add this css for remove Powered By Text from CKEditor
// .ck-body-wrapper {
//   display: none !important;
// }
// For CKEditor height
// .ck-editor__editable_inline {
//   min-height: 100px;
// }
